import React, {useState} from 'react'
import I18n from '../utils/I18n'
import Translation from '../utils/Translation'
import certifications from '../../data/certifications.json'

const CertificationBanner = ({ data }) => {
  const [isCloseBanner, setCloseBanner] = useState(true);
  const { slug, title, roleImg, lang } = data

  const i18n = new I18n({ lang })
  const breadcrumbTitle = title.replace(/<[^>]*>/g, ' ')
  const itemEN = Translation.getItems(certifications, null, 'slug', 'en').find(it => it.slug === slug)
  const customCssClass = {
    'associate': 'associate',
    'aiassociate': 'associate',
    'mulesoftassociate': 'associate'
  }

  const backgroundClass = Object.keys(customCssClass).includes(slug) ? `certification-banner_${customCssClass[slug]}` : ''
  const heroImageClass = Object.keys(customCssClass).includes(slug) ? `certification-banner_image_${customCssClass[slug]}` : ''

  return (
    <>
      {slug === 'aispecialist' && isCloseBanner && (
        <div className="site-down-error">
          &nbsp;&nbsp;&nbsp; <span className="site-down-text">COMING MARCH 3, 2025: AI Specialist is evolving into Agentforce Specialist. See <a target='_blank' href='https://trailhead.salesforce.com/help?article=Salesforce-Certified-Agentforce-Specialist-FAQ'>FAQ</a> to learn more.</span> <span className="close-icon" onClick={(e) => setCloseBanner(!isCloseBanner)}>X</span>
        </div>
      )}
      <div className={`certification-banner ${backgroundClass} slds-is-relative`}>
        <div className="certification-banner_breadcrumb">
          <a href={`/${lang}/credentials/administratoroverview`}>{i18n.text('breadcrumb_root')}</a> &gt; {breadcrumbTitle}
        </div>
        <img className={`certification-banner_image ${heroImageClass}`} src={itemEN ? itemEN.roleImg : roleImg} />
        <div className="certification-banner-text">
          <h1 className="certification-banner_title slds-container--medium slds-container--center slds-text-align--center" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </div>
    </>
  )
}

export default CertificationBanner
